import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import hoistNonReactStatic from 'hoist-non-react-statics';
import getRedirectLocation from 'core/routes/getRedirectLocation';
import initApollo from './initApollo';

export default function withApollo(WrappedComponent) {
  /* eslint-disable react/prefer-stateless-function */
  class WithApollo extends React.Component {
    static async getInitialProps(ctx) {
      const {
        ctx: { req, res, pathname },
      } = ctx;

      let authToken;
      let role;
      let myProfile;
      let loginMethod;
      if (process.browser) {
        authToken = localStorage.getItem('authToken');
        role = localStorage.getItem('role');
        myProfile = localStorage.getItem('myProfile')
          ? JSON.parse(localStorage.getItem('myProfile'))
          : {};
        loginMethod = localStorage.getItem('loginMethod');
      } else {
        ({
          cookies: { authToken, role, myProfile, loginMethod },
        } = req);
        myProfile = myProfile ? JSON.parse(myProfile) : {};
      }

      if (!process.browser) {
        const redirectLocation = getRedirectLocation({
          authToken,
          role,
          myProfile,
          loginMethod,
          pathname,
          originalUrl: req.originalUrl,
        });
        if (redirectLocation) {
          res.writeHead(302, { Location: redirectLocation });
          res.end();
          return {};
        }
      }

      const requestPath = req && req.get('request-path');

      // If getInitialProps was called without a request object,
      // then this was most likely a due to a `pushState` rather than a full page request.
      const hasRequestObject = !!req;

      const apollo = initApollo({}, { authToken, role });

      let wrappedComponentProps = {};
      if (WrappedComponent.getInitialProps) {
        wrappedComponentProps = await WrappedComponent.getInitialProps(ctx);
      }

      ctx.ctx.apolloClient = apollo;

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      const apolloState = {};

      if (!process.browser) {
        apolloState.data = apollo.cache.extract();
      } else {
        apolloState.data = {};
      }

      return {
        ...wrappedComponentProps,
        apolloState,
        authToken,
        role,
        loginMethod,
        myProfile,
        requestPath,
        hasRequestObject,
      };
    }

    static propTypes = {
      apolloState: PropTypes.shape({
        data: PropTypes.object,
      }).isRequired,
      role: PropTypes.string,
      authToken: PropTypes.string,
      router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    static defaultProps = {
      role: '',
      authToken: '',
    };

    constructor(props) {
      super(props);
      // `getDataFromTree` renders the component first, then the client is passed off as a property.
      // After that, rendering is done using Next's normal rendering pipeline
      this.apollo = initApollo(props.apolloState.data, {
        authToken: props.authToken,
        router: props.router,
      });
      // State must be initialized if getDerivedStateFromProps is used
      this.state = {};
    }

    render() {
      return (
        <ApolloProvider client={this.apollo}>
          <WrappedComponent {...this.props} />
        </ApolloProvider>
      );
    }
  }

  hoistNonReactStatic(WithApollo, WrappedComponent, { getInitialProps: true });

  return WithApollo;
}
