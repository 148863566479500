const emrEnabled = process.env.EMR_ENABLED;

const checkPermission = (profile, requestedPermission) => {
  const currentRole = profile.roles.find(r => r.role === profile.role);
  const currentPermissions = currentRole?.permissions;

  return currentPermissions?.includes(requestedPermission);
};

const checkRole = (profile, requestedRole) => {
  return profile.role === requestedRole;
};

export default [
  {
    icon: 'dashboard',
    key: 'provider-dashboard',
    name: 'Dashboard',
    roles: ['provider'],
    to: '/provider/dashboard',
    displayRule: () => emrEnabled,
  },
  {
    icon: 'home',
    key: 'admin-facilities',
    name: 'Facilities',
    refreshPage: false,
    roles: ['appAdmin', 'provider'],
    to: '/facilities',
    displayRule: myProfile =>
      (checkRole(myProfile, 'appAdmin') && checkPermission(myProfile, 'manageFacilities')) ||
      (checkRole(myProfile, 'provider') && emrEnabled),
  },
  {
    icon: 'team',
    key: 'admin-accounts',
    name: 'Accounts',
    refreshPage: false,
    roles: ['appAdmin'],
    to: '/admin/accounts',
    displayRule: myProfile =>
      checkRole(myProfile, 'appAdmin') && checkPermission(myProfile, 'manageAccounts'),
  },
  {
    icon: 'calendar',
    key: 'admin-calendar',
    name: 'Calendar',
    refreshPage: false,
    roles: ['appAdmin'],
    to: '/admin/calendar',
    displayRule: myProfile =>
      checkRole(myProfile, 'appAdmin') &&
      (checkPermission(myProfile, 'viewShifts') || checkPermission(myProfile, 'manageShifts')),
  },
  {
    icon: 'team',
    key: 'admin-patients',
    name: 'Patients',
    refreshPage: false,
    roles: ['appAdmin', 'provider'],
    to: '/patients/list',
    displayRule: myProfile =>
      (emrEnabled &&
        checkRole(myProfile, 'appAdmin') &&
        checkPermission(myProfile, 'manageFacilities')) ||
      (emrEnabled && checkRole(myProfile, 'provider')),
  },
  {
    icon: 'schedule',
    key: 'admin-patient-scheduling',
    name: 'Patient Scheduling',
    // modal: true,
    // modalKey: 'addPatientSchedule',
    roles: ['appAdmin', 'facilityAdmin'],
    to: '/patients/schedule',
  },
  {
    icon: 'video-camera',
    key: 'facility-telemed',
    name: 'Telemed',
    refreshPage: false,
    roles: ['nurse'],
    to: '/',
    displayRule: myProfile => myProfile.facility?.facSettings?.telemedWorkflow !== 'withCode',
  },
  {
    icon: 'team',
    key: 'facility-calendar',
    name: 'Calendar',
    refreshPage: false,
    roles: ['nurse'],
    to: '/calendar',
    displayRule: myProfile => myProfile.facility?.facSettings?.telemedWorkflow === 'withCode',
  },
  {
    icon: 'team',
    key: 'facility-admin',
    name: 'Admin',
    refreshPage: false,
    roles: ['nurse'],
    to: '/facility/admin/patients',
    displayRule: (myProfile, loginMethod) =>
      !myProfile.facility?.facSettings?.telemedWorkflow ||
      myProfile.facility?.facSettings?.telemedWorkflow === 'withCode' ||
      (myProfile.facility?.facSettings?.telemedWorkflow === 'directCall' && loginMethod === 'pcc'),
  },
  {
    icon: 'video-camera',
    key: 'patient-telemed',
    name: 'Telemed',
    refreshPage: false,
    roles: ['patient'],
    to: '/encounter/pin',
  },
  {
    icon: 'video-camera',
    key: 'admin-telemed-encounters',
    name: 'Telemed Encounters',
    refreshPage: false,
    roles: ['appAdmin'],
    to: '/admin/telemed-encounters',
  },
  {
    icon: 'video-camera',
    key: 'provider-telemed-encounters',
    name: 'Telemed Encounters',
    refreshPage: false,
    roles: ['provider'],
    to: '/provider',
  },
  {
    icon: 'calendar',
    key: 'provider-calendar',
    name: 'Calendar',
    refreshPage: true,
    roles: ['provider'],
    to: '/provider/calendar',
  },
  {
    icon: 'solution',
    key: 'emr-dashboard',
    name: 'Encounters',
    roles: ['provider', 'appAdmin'],
    to: '/encounters',
    displayRule: () => emrEnabled,
  },
  {
    icon: 'plus',
    key: 'emr-encounter-new',
    name: 'New Encounter',
    roles: ['provider'],
    to: '/encounters/add',
    displayRule: () => emrEnabled,
  },
  {
    icon: 'home',
    key: 'd2c-home',
    name: 'Home',
    roles: ['d2cPatient'],
    to: '/d2c/home',
  },
  {
    icon: 'file-text',
    key: 'admin-reports',
    name: 'Reports',
    refreshPage: false,
    roles: ['appAdmin'],
    to: '/admin/reports',
    displayRule: myProfile => checkPermission(myProfile, 'accessTechnicalFeatures'),
  },
  {
    icon: 'setting',
    key: 'technical-settings',
    name: 'Technical Settings',
    refreshPage: false,
    roles: ['appAdmin'],
    to: '/technical-settings',
    displayRule: myProfile => checkPermission(myProfile, 'accessTechnicalFeatures'),
  },
];
