import { Icon, notification } from 'antd';
import NextApp from 'next/app';
import Router from 'next/router';
import React from 'react';
import { Provider as MobXProvider } from 'mobx-react';
import getConfig from 'next/config';
import * as Sentry from '@sentry/browser';
import rootMobXStores from 'core/stores';
import withMobX from 'core/stores/withMobX';
import withApollo from 'core/apollo/withApollo';
import withMyProfile from 'graphql/hocs/profile/withMyProfile';
import getRedirectLocation from 'core/routes/getRedirectLocation';
import refreshActivity from 'core/utils/refreshActivity';
import '../styles/less/styles.less';
import '../styles/less/calendar.less';

const { sentryDsn } = getConfig().publicRuntimeConfig;
Sentry.init({ dsn: sentryDsn });

Router.events.on('routeChangeStart', url => {
  notification.open({
    key: url,
    message: 'Loading...',
    duration: 0,
    icon: <Icon type="loading" />,
    placement: 'bottomLeft',
    style: {
      width: '180px',
    },
    closeIcon: null,
    className: 'loading-notification',
  });
});

const clearLoading = url => notification.destroy(url);
Router.events.on('routeChangeComplete', clearLoading);
Router.events.on('routeChangeError', (err, url) => clearLoading(url));

@withApollo
@withMobX
@withMyProfile
class MyApp extends NextApp {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    if (process.browser) {
      const redirectLocation = getRedirectLocation({
        authToken: true,
        role: rootMobXStores.authStore.role,
        myProfile: rootMobXStores.authStore.myProfile,
        loginMethod: rootMobXStores.authStore.loginMethod,
        pathname: ctx.pathname,
      });
      if (redirectLocation) {
        return Router.push(redirectLocation);
      }
    }

    return { pageProps };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  componentDidMount() {
    const { role, myProfile, loginMethod } = this.props;
    rootMobXStores.authStore.setRole(role);
    rootMobXStores.authStore.setProfile(myProfile);
    rootMobXStores.authStore.setLoginMethod(loginMethod);
    if (process.browser) {
      document.body.addEventListener('mousemove', refreshActivity);
      document.body.addEventListener('mousedown', refreshActivity);
      document.body.addEventListener('keypress', refreshActivity);
      refreshActivity();
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <MobXProvider suppressChangedStoreWarning>
        <Component {...pageProps} />
      </MobXProvider>
    );
  }
}

export default MyApp;
