import React from 'react';
import { Provider } from 'mobx-react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import rootMobxStores from './index';

function withMobX(WrappedComponent) {
  /* eslint-disable react/prefer-stateless-function */
  class WithMobX extends React.Component {
    render() {
      return (
        <Provider {...rootMobxStores}>
          <WrappedComponent {...this.props} />
        </Provider>
      );
    }
  }

  hoistNonReactStatic(WithMobX, WrappedComponent);

  return WithMobX;
}

export default withMobX;
