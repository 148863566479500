import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable, split, concat } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getOperationAST } from 'graphql';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';
import getConfig from 'next/config';
import Router from 'next/router';
import rootMobxStores from 'core/stores';
import omitTypenameLink from './omitTypenameLink';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const { wsUrl, graphqlUrl: externalGraphqlUrl } = publicRuntimeConfig;
const { graphqlUrl: internalGraphqlUrl } = serverRuntimeConfig;

let graphqlUrl = internalGraphqlUrl;
if (process.browser) {
  graphqlUrl = externalGraphqlUrl;
}

let apolloClient = null;

if (!process.browser) {
  global.fetch = fetch;
}

const create = (initialState, options) => {
  // error handling for Apollo Link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${JSON.stringify(path)}`,
        );
      });
    }

    if (networkError && process && process.browser) {
      console.log(networkError);
      if (Router.pathname !== '/account/login') {
        Router.push('/account/logout', '/account/logout');
      }
    }
  });
  let authorizationHeader = {};
  if (options.authToken) {
    authorizationHeader = { Authorization: options.authToken };
  }

  // Set auth context
  // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-context
  const authLink = setContext((__, { headers }) => ({
    headers: {
      ...headers,
      ...authorizationHeader,
    },
  }));

  const uploadLink = createUploadLink({
    uri: graphqlUrl,
  });

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  const httpLink = concat(requestLink, uploadLink);
  let link = httpLink;

  if (process.browser) {
    const clientSessionId = Date.now().toString();
    const { pathname, query = {} } = options.router;
    const { encounterId } = query;
    const connectionParams = {
      authToken: options.authToken,
      encounterId,
      clientSessionId,
    };
    if (pathname.includes('/encounter/video') || pathname.includes('/provider/video')) {
      connectionParams.isVideo = true;
    }

    const wsLink = new WebSocketLink({
      uri: wsUrl,
      options: {
        reconnect: true,
        connectionParams,
        connectionCallback: () => {
          rootMobxStores.authStore.setWsConnection(true);
        },
        lazy: true,
      },
    });

    rootMobxStores.authStore.setClientSessionId(clientSessionId);

    link = split(
      operation => {
        const operationAST = getOperationAST(operation.query, operation.operationName);
        return !!operationAST && operationAST.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
  }

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    link: ApolloLink.from([omitTypenameLink, authLink, errorLink, link]),
    cache: new InMemoryCache().restore(initialState || {}),
  });
};

export default function initApollo(initialState, options) {
  // Make sure to create a new clsient for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options);
  }

  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
