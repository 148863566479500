export default [
  {
    pathname: '/',
    roles: ['nurse'],
  },
  {
    pathname: '/encounter/patient',
    roles: ['nurse'],
  },
  {
    pathname: '/admin/telemed-encounters',
    roles: ['appAdmin'],
  },
  {
    pathname: '/encounter/provider',
    roles: ['nurse'],
  },
  {
    pathname: '/encounter/info',
    roles: ['nurse'],
  },
  {
    pathname: '/encounter/ecg/[encounterId]/[ecgId]',
    roles: ['nurse', 'provider'],
  },
  {
    pathname: '/encounter/pin',
    roles: ['patient'],
  },
  {
    pathname: '/encounter/consent/[encounterId]',
    roles: ['patient'],
  },
  {
    pathname: '/encounter/video',
    roles: ['nurse'],
  },
  {
    pathname: '/encounter/video/[encounterId]',
    roles: ['patient'],
  },
  {
    pathname: '/facility/admin/patients',
    roles: ['nurse'],
  },
  {
    pathname: '/provider',
    roles: ['provider'],
  },
  {
    pathname: '/provider/info/[encounterId]',
    roles: ['provider', 'appAdmin'],
  },
  {
    pathname: '/provider/video/[encounterId]',
    roles: ['provider'], // todo
  },
  {
    pathname: '/provider/consent/[encounterId]',
    roles: ['provider'],
  },
  {
    pathname: '/provider/med-records-release/[encounterId]',
    roles: ['provider'],
  },
  {
    pathname: '/encounter/ended/[...params]',
    roles: ['nurse', 'patient', 'provider'],
  },
  {
    pathname: '/my-account',
    roles: ['provider', 'nurse', 'appAdmin', 'patient'],
  },
  {
    pathname: '/facilities',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/facilities/view/[facilityId]',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/facilities/update/[facilityId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/facilities/manage-providers/[facilityId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/facilities/add',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/add',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/view/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/update/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/update-password/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/create-sh-account/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/update-sh-password/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/accounts/manage-facilities/[accountId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/reports',
    roles: ['appAdmin'],
  },
  {
    pathname: '/admin/reports/add',
    roles: ['appAdmin'],
  },
  {
    pathname: '/facilities/survey/[facilityId]',
    roles: ['appAdmin'],
  },
  {
    pathname: '/calendar',
    roles: ['nurse'],
  },
  {
    pathname: '/provider/calendar',
    roles: ['provider'],
  },
  {
    pathname: '/admin/calendar',
    roles: ['appAdmin'],
  },
  {
    pathname: '/shifts/telemed',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/shifts/rounding',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/shifts/wound-round',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/shifts/telemed',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/encounters/add',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/encounters/notes/[...params]',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/encounters/addendum/[...params]',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/encounters/preview/[emrEncounterId]',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/encounters',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/provider/dashboard',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: 'provider/list',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/patients/view/[patientId]',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/patients/schedule',
    roles: ['appAdmin'],
  },
  {
    pathname: '/patients/schedule/[scheduleId]',
    roles: ['appAdmin'],
  },
  {
    pathname: 'account/completeSignup',
  },
  {
    pathname: 'd2cPatient/home',
  },
  {
    pathname: 'd2cPatient/scheduleCall',
  },
  {
    pathname: '/telemed-encounters',
    roles: ['appAdmin', 'provider'],
  },
  {
    pathname: '/system-settings',
    roles: ['appAdmin'],
  },
];
