import { ApolloLink } from 'apollo-link';

/* eslint-disable no-param-reassign */
const omitTypenameRecursively = obj => {
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (key === '__typename') {
        delete obj[key];
      }
      if (typeof obj[key] === 'object') {
        omitTypenameRecursively(obj[key]);
      }
    });
  }
};

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    omitTypenameRecursively(operation.variables);
  }
  return forward ? forward(operation) : null;
});

export default omitTypenameLink;
