import menus from 'registry/menus';
import routesList from 'registry/routesList';
import checkIfSuperAdmin from '../utils/checkIfSuperAdmin';

export default function getRedirectLocation({
  authToken,
  role,
  myProfile,
  loginMethod,
  pathname,
  originalUrl = '/',
}) {
  // Redirect to login page when there is no auth token present
  const currentRoute = routesList.find(r => r.pathname === pathname);

  const currentRouteRequiresRole =
    currentRoute && currentRoute.roles && currentRoute.roles.length > 0;
  if (currentRouteRequiresRole && !authToken) {
    return `/account/login?ref=${encodeURIComponent(originalUrl)}`;
  }

  let isSuperAdmin = false;
  if (myProfile) {
    isSuperAdmin = checkIfSuperAdmin(myProfile._id);
  }

  let hasCurrentMenuAccess = false;
  if (currentRoute) {
    hasCurrentMenuAccess = true;
    const menu = menus.find(m => m.to === currentRoute.pathname);
    if (menu) {
      hasCurrentMenuAccess = menu.roles.includes(role);
      if (hasCurrentMenuAccess && menu.displayRule && !isSuperAdmin) {
        hasCurrentMenuAccess = menu.displayRule(myProfile, loginMethod);
      }
    }
  }

  if (currentRouteRequiresRole && (!currentRoute.roles.includes(role) || !hasCurrentMenuAccess)) {
    const firstMenu = menus.find(m => {
      let hasMenuAccess = m.roles.includes(role);
      if (hasMenuAccess && m.displayRule && !isSuperAdmin) {
        hasMenuAccess = m.displayRule(myProfile, loginMethod);
      }
      return hasMenuAccess;
    });
    return firstMenu.to;
  }

  return '';
}
